import { computed, Ref, ref } from 'vue';
import usePortfolioTree from './usePortfolioTree';
import {
  IPortfolioTreeStrategy,
  IPortfolioTreeSubportfolio,
  isPortfolioTreeStrategy,
  isPortfolioTreeSubportfolio,
} from '@/types/IPortfolioTree';
import { useFxConvertedStrategyTracksByDate } from './useFxConvertedStrategyTracksByDate';
import { MeasurementTypeConstants } from '@/constants/MeasurementTypeConstants';
import { createGlobalState } from '@vueuse/shared';

export const DEFAULT_NOTIONAL = 1_000;

/**
 * Golden source of Measurement Type for the portfolio
 */
export const useParentDriverType = createGlobalState(() => {
  return ref<MeasurementTypeConstants>(MeasurementTypeConstants.WEIGHTS);
});

export const useTriangulationDate = createGlobalState(() => {
  return ref<string | null>(null);
});

export function useTriangulation(
  portfolioTreeItem: Ref<IPortfolioTreeStrategy | IPortfolioTreeSubportfolio | null> | null = null,
) {
  const { masterPortfolioTree } = usePortfolioTree();

  const portfolioNotional = computed(() => {
    return masterPortfolioTree.value?.portfolioTree.portfolioValue ?? DEFAULT_NOTIONAL;
  });

  const portfolioCash = computed(() => {
    return masterPortfolioTree.value?.portfolioTree?.portfolioCash ?? 0;
  });

  const strategyCode = computed(() => {
    if (portfolioTreeItem?.value) {
      if (isPortfolioTreeStrategy(portfolioTreeItem.value)) {
        return portfolioTreeItem.value.strategy?.code ?? portfolioTreeItem.value.reference ?? null;
      }

      if (portfolioTreeItem.value.isMasterIndex && portfolioTreeItem.value.reference) {
        return portfolioTreeItem.value.reference;
      }
    }

    return null;
  });

  const { isPriceDataLoading, itemConvertedStrategyPrice, fxConvertedPriceData } =
    useFxConvertedStrategyTracksByDate(strategyCode);

  const itemWeighting = computed(() => {
    if (!portfolioTreeItem?.value) {
      return 0;
    }

    return portfolioTreeItem.value.weighting ?? 0;
  });

  /**
   * Find the parent portfolio NAV for the given portfolio tree id
   * That can be found at the root level or the master index level
   */
  const findParentPortfolioNav = ({
    portfolioTree,
    portfolioTreeId,
    portfolioValue,
    portfolioUnits,
  }: {
    portfolioTree: IPortfolioTreeSubportfolio;
    portfolioTreeId: string;
    portfolioValue?: number;
    portfolioUnits?: number;
  }): number | undefined => {
    // Must use ?? to use 0, and use default if undefined
    portfolioValue = portfolioValue ?? portfolioTree.portfolioValue ?? DEFAULT_NOTIONAL;
    // Must use || to default to 1 if 0 or undefined
    portfolioUnits = portfolioUnits || portfolioTree.unit || 1;

    if (portfolioTree.portfolioTreeId === portfolioTreeId) {
      return portfolioValue / portfolioUnits;
    }

    for (const component of portfolioTree.components) {
      // if the component is the one we're looking for, return the component itself
      if (component.portfolioTreeId === portfolioTreeId) {
        return portfolioValue / portfolioUnits;
      }

      if (isPortfolioTreeSubportfolio(component)) {
        const updatedPortfolioNav = findParentPortfolioNav({
          portfolioTree: component,
          portfolioTreeId,
          portfolioValue: component.portfolioValue ?? portfolioValue,
          portfolioUnits: component.unit ?? portfolioUnits,
        });
        if (updatedPortfolioNav) return updatedPortfolioNav;
      }
    }
  };

  /**
   * Calculate unit from triangulation maths
   * only applicable to strategies and Master Index, otherwise it will be 0
   * It calculates the unit by (weighting * portfolio notional) / (strategy price * 100)
   * *100 is necessary as the weighting is multiplied by 100
   *
   * When no strategy price is available, it scales the unit based on the ratio between the new weight and the old weight
   */
  const calculateUnit = (scalingFactor: number) => {
    if (masterPortfolioTree.value && portfolioTreeItem?.value) {
      if (isPortfolioTreeStrategy(portfolioTreeItem.value) || portfolioTreeItem.value.isMasterIndex) {
        if (itemConvertedStrategyPrice.value != null) {
          const portfolioNav =
            findParentPortfolioNav({
              portfolioTree: masterPortfolioTree.value.portfolioTree,
              portfolioTreeId: portfolioTreeItem.value.portfolioTreeId,
            }) ?? DEFAULT_NOTIONAL;

          return (itemWeighting.value * portfolioNav) / (itemConvertedStrategyPrice.value * 100);
        }

        // Handle items without a price
        // Scale the unit based on ratio between new weight and old weight
        const scaledUnit = (portfolioTreeItem.value.unit ?? 1) * scalingFactor;

        return scaledUnit;
      }
    }
  };

  const itemUnit = computed(() => {
    if (portfolioTreeItem?.value) {
      if (isPortfolioTreeStrategy(portfolioTreeItem.value) || portfolioTreeItem.value.isMasterIndex) {
        return portfolioTreeItem.value.unit ?? 0;
      }
    }

    return 0;
  });

  /**
   * Calculate weighting from triangulation maths
   * For strategy and Master Index, it calculates the weighting of the strategy by unit * price/ portfolio notional
   *
   * When no strategy price is available, it scales the unit based on the ratio between the new weight and the old weight
   */
  const calculateWeighting = (scalingFactor: number) => {
    if (masterPortfolioTree.value && portfolioTreeItem?.value) {
      if (isPortfolioTreeStrategy(portfolioTreeItem.value) || portfolioTreeItem.value.isMasterIndex) {
        if (itemConvertedStrategyPrice.value != null) {
          const portfolioNav =
            findParentPortfolioNav({
              portfolioTree: masterPortfolioTree.value.portfolioTree,
              portfolioTreeId: portfolioTreeItem.value.portfolioTreeId,
            }) ?? DEFAULT_NOTIONAL;

          return (itemConvertedStrategyPrice.value * itemUnit.value * 100) / portfolioNav;
        }

        // Handle items without a price
        // Scale the unit based on ratio between new unit and old unit
        const scaledWeighting = (portfolioTreeItem.value.weighting ?? 1) * scalingFactor;

        return scaledWeighting;
      }
    }
  };

  const getComponentStrategySum = (components: (IPortfolioTreeStrategy | IPortfolioTreeSubportfolio)[]) => {
    let sum = 0;
    for (const component of components) {
      if (isPortfolioTreeStrategy(component)) {
        const unit = component.unit ?? 0;
        const strategyCode = component.strategy?.code ?? component.reference ?? null;
        if (strategyCode && fxConvertedPriceData.data.value) {
          const fxConvertedPrice = fxConvertedPriceData.data.value.strategyNavConvertedMap[strategyCode]?.value ?? 0;
          sum += fxConvertedPrice * unit;
        }
      } else {
        sum += getComponentStrategySum(component.components);
      }
    }

    return sum;
  };

  /**
   * Total equity notional
   */
  const calculatedTotalNotional = computed(() => {
    return getComponentStrategySum(masterPortfolioTree.value?.portfolioTree?.components || []);
  });

  /**
   * Calculated item notional
   * For subportfolio, it calculates the sum of notional of all the strategies/ equity in the subportfolio
   * For strategy, it calculates the unit * price * fx
   */
  const calculatedItemNotional = computed(() => {
    if (!portfolioTreeItem?.value) return 0;

    if (isPortfolioTreeStrategy(portfolioTreeItem.value) || portfolioTreeItem.value.isMasterIndex) {
      if (itemConvertedStrategyPrice.value != null) {
        return (portfolioTreeItem.value?.unit ?? 0) * itemConvertedStrategyPrice.value;
      }
      return 0;
    }

    return getComponentStrategySum([portfolioTreeItem.value]);
  });

  return {
    portfolioNotional,
    portfolioCash,
    isPriceDataLoading,
    calculatedTotalNotional,
    calculatedItemNotional,

    calculateUnit,
    calculateWeighting,
  };
}
